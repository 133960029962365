import React, { useState } from "react";
import Img from "gatsby-image";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import { HiMenuAlt1 } from "@react-icons/all-files/hi/HiMenuAlt1";
import { IoCloseOutline } from "@react-icons/all-files/io5/IoCloseOutline";

import { getLocaleValue } from "../../../helpers/get-locale-value";
import LocaleMenu from "../../locale/component/locale-menu/locale-menu-controller";

import SubNavigation from "../sub-navigation";
import Navigation from "../../shared-components/navigation";
import Image from "../image";

const HEADER_HEIGHT = "64px";
const SUB_MENU_HEIGHT = "54px";
const PADDING_HORIZONTAL = "24px";
const PADDING_VERTICAL = "0px";

const useStyles = makeStyles({
  header: {
    background: props => props.background,
    fontSize: "0.9rem",
    color: "#fff",
    minHeight: HEADER_HEIGHT,
    height: HEADER_HEIGHT,
    padding: `${PADDING_VERTICAL} ${PADDING_HORIZONTAL}`,
    position: "relative",
    zIndex: 3,
  },
  hamburger: {
    display: "flex",
    position: "absolute",
    left: PADDING_HORIZONTAL,
    color: "#fff",
  },
  localization: {
    display: "flex",
    position: "absolute",
    right: PADDING_HORIZONTAL,
  },
  siteLogoPopup: {
    width: "120px",
  },
  siteLogo: {
    width: "80px",
    maxHeight: HEADER_HEIGHT,
  },
  menu: {
    minHeight: "100vh",
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: props => props.background,
    overflow: "auto",
  },
  menuItem: {
    display: "block",
    textTransform: "uppercase",
    color: "#fff",
    marginTop: "16px",
    marginBottom: "16px",

    "&:last-child": {
      marginBottom: "0px",
    },
  },
  menuDropdown: {
    display: "block",
    textAlign: "center",
    width: "100%",

    "&:hover $dropdownList": {
      display: "flex",
    },
  },
  dropdownList: {
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    flex: "1",
    top: HEADER_HEIGHT,
    minHeight: SUB_MENU_HEIGHT,
    zIndex: 3,
    flexDirection: "column",
    fontSize: "0.8rem",
    backgroundColor: "#fff",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  menuDropdownItem: {
    textTransform: "uppercase",
    color: "#2a3180",
    marginTop: "14px",
    marginBottom: "164x",

    "&:first-child": {
      marginTop: "0px",
    },

    "&:last-child": {
      marginBottom: "0px",
    },
  },
});

const HeaderView = ({
  locale,
  locales,
  navigations,
  theme,
  location,
  reloadRequired,
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const {
    iconSite,
    siteName,
    navBgColor: inputNavBgColor,
    subNavBgColor: inputSubNavBgColor,
    mobileNavBgColor: inputMobileNavBgColor,
  } = theme;
  const classes = useStyles({ background: inputMobileNavBgColor });

  // Get sub navigation link
  function getSubNavigationLink(
    parentUrl,
    subLink,
    subAppendParentUrl,
    subRedirect,
    subTarget,
    currentLocation
  ) {
    if (subRedirect) {
      return subRedirect;
    }

    // If no location, return sub url
    if (!currentLocation) {
      return subLink;
    }
    let modifiedSubLink;
    if (subTarget === "_anchor") {
      modifiedSubLink = subLink.startsWith("#") ? subLink : `#${subLink}`;
      if (currentLocation.pathname.includes(parentUrl)) {
        return modifiedSubLink;
      }
      return subAppendParentUrl
        ? `${parentUrl}${modifiedSubLink}`
        : `${modifiedSubLink}`;
    }
    modifiedSubLink = subLink.startsWith("/") ? subLink : `/${subLink}`;
    return subAppendParentUrl
      ? `${parentUrl}${modifiedSubLink}`
      : `${modifiedSubLink}`;
  }

  // Render mobile menu + submenu
  function renderMobileMenuItem(link, index) {
    const localeDisplayCode = locales
      ? locales.find(lang => lang.code == locale).display_code
      : "";

    if (link.sub_navigations.length === 0) {
      return (
        <Navigation
          className={`text-hover ${classes.menuItem} ${
            location.pathname === link.sitemap.path ||
            location.pathname.replace(`/${localeDisplayCode}`, "") ===
              link.sitemap.path
              ? "text-active"
              : ""
          }`}
          key={`mi-${index}`}
          to={link.sitemap.path}
          redirect={link.sitemap.path}
          target={link.target}
          reloadRequired={link.sitemap.reload_required}
          onClick={() => {
            setOpenMobileMenu(false);
          }}
        >
          {getLocaleValue(locale, link.variants).content}
        </Navigation>
      );
    }
    return (
      <Box className={classes.menuDropdown} key={`mdi-${index}`}>
        <Navigation
          className={`text-hover ${classes.menuItem} ${
            location.pathname.includes(link.sitemap.path) ? "text-active" : ""
          }`}
          to={link.sitemap.path}
          redirect={link.sitemap.path}
          target={link.target}
          reloadRequired={link.sitemap.reload_required}
        >
          {getLocaleValue(locale, link.variants).content}
        </Navigation>
        <Box className={classes.dropdownList}>
          {link.sub_navigations &&
            link.sub_navigations.map((sub, i) => (
              <SubNavigation
                className={`text-hover ${classes.menuDropdownItem}`}
                key={`mdi-${i}`}
                to={sub.sitemap.path}
                // to={getSubNavigationLink(
                //   link.sitemap.path,
                //   sub.url,
                //   sub.append_parent_url,
                //   sub.sitemap.path,
                //   sub.target,
                //   location
                // )}
                reloadRequired={sub.sitemap.reload_required}
                onClick={() => {
                  setOpenMobileMenu(false);
                }}
              >
                {getLocaleValue(locale, sub.variants).content}
              </SubNavigation>
            ))}
        </Box>
      </Box>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.header}
      id="header"
    >
      <Box className={classes.hamburger}>
        <HiMenuAlt1
          size="1.6em"
          onClick={() => {
            setOpenMobileMenu(!openMobileMenu);
          }}
        />
        <Popup
          open={openMobileMenu}
          closeOnDocumentClick={false}
          contentStyle={{
            minHeight: "100%",
            height: "100%",
            minWidth: "100%",
            width: "100%",
          }}
        >
          <Box className={classes.menu} py={10}>
            <IoCloseOutline
              size="3.0em"
              style={{
                color: "#fff",
                position: "absolute",
                top: "10px",
                left: "10px",
              }}
              onClick={() => {
                setOpenMobileMenu(false);
              }}
            />
            <Box mb={5}>
              <Navigation to="/">
                <Image
                  className={classes.siteLogoPopup}
                  imageObject={iconSite}
                />
              </Navigation>
            </Box>
            {navigations &&
              navigations.map((n, i) => renderMobileMenuItem(n, i))}
          </Box>
        </Popup>
      </Box>
      <Navigation to="/">
        <Image className={classes.siteLogo} imageObject={iconSite} />
      </Navigation>
      <Box className={classes.localization}>
        <LocaleMenu
          locales={locales}
          location={location}
          reloadRequired={reloadRequired}
        />
      </Box>
    </Grid>
  );
};

HeaderView.defaultProps = {};

export default HeaderView;
