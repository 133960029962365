import React, { useEffect, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";
import { storeLocales } from "../../locale/action";

import HeaderDesktopView from "./header-view-desktop";
import HeaderTabletView from "./header-view-tablet";
import ResponsiveWrapper from "../../shared-components/responsive-wrapper";

const HeaderController = ({
  location,
  header,
  siteSetting,
  reloadRequired,
}) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const { navigations, locales: languages } = header;

  const { site_name, site_logo, stakeholder_logo } = siteSetting;

  useEffect(() => {
    // locales
    const ascendingLocale = languages.sort(
      (a, b) => Number(a.id) - Number(b.id)
    );

    stableDispatch(storeLocales(ascendingLocale));
  }, [header, stableDispatch]);

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const locales = useSelector(state => {
    return state.localeReducer.locales;
  }, []);

  // TODO: Feed in background colors/font for nav and sub nav bar
  const theme = {
    iconStakeHolder: stakeholder_logo,
    iconSite: site_logo,
    siteName: site_name,
    navBgColor:
      "linear-gradient(87.88deg, #2A3180 1%, #1F71B8 26.86%, #189CD9 79.62%, #2A3180 102.38%)",
    mobileNavBgColor: "linear-gradient(#2A3180, #1F71B8)",
    subNavBgColor: "#fff",
  };

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <HeaderDesktopView
          locale={locale}
          locales={locales}
          navigations={navigations}
          theme={theme}
          location={location}
          reloadRequired={reloadRequired}
        />
      }
      TabletComponent={
        <HeaderTabletView
          locale={locale}
          locales={locales}
          navigations={navigations}
          theme={theme}
          location={location}
          reloadRequired={reloadRequired}
        />
      }
      PhoneComponent={
        <HeaderTabletView
          locale={locale}
          locales={locales}
          navigations={navigations}
          theme={theme}
          location={location}
          reloadRequired={reloadRequired}
        />
      }
    />
  );
};

export default HeaderController;
