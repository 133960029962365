import React from "react";

import { Box, Grid, useMediaQuery } from "@material-ui/core";

import Navigation from "../navigation";

import { getLocaleValue } from "../../../helpers/get-locale-value";
import Image from "../image";

const FooterView = ({
  locale,
  iconCompanyImage,
  copyrightNotice,
  navigations,
  bgColorCode,
}) => {
  return (
    <Box display="flex" flexDirection="column" bgcolor={bgColorCode} p={1}>
      <Box
        flex={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        color="white"
        fontSize="0.8em"
      >
        <Box mr={1} maxWidth={50}>
          <Image imageObject={iconCompanyImage} />
        </Box>
        {copyrightNotice}
      </Box>
      <Box display="flex" width="100%" fontSize="0.8em">
        {navigations?.map((n, i) => (
          <Box
            display="flex"
            key={i}
            mr={i != navigations.length - 1 ? 1 : 0}
            color="white"
          >
            <Box pr={1}>
              <Navigation
                style={{ color: "white" }}
                to={n.sitemap.path}
                redirect={n.sitemap.path}
                reloadRequired={n.sitemap.reload_required}
              >
                {getLocaleValue(locale, n.variants).content}
              </Navigation>
            </Box>
            {i + 1 < navigations.length ? "|" : ""}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FooterView;
