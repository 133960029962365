import React, { useState } from "react";
import Popup from "reactjs-popup";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { HiOutlineGlobeAlt } from "@react-icons/all-files/hi/HiOutlineGlobeAlt";
import { IoCloseOutline } from "@react-icons/all-files/io5/IoCloseOutline";

const useStyles = makeStyles({
  localeSelectionTitle: {
    textTransform: "uppercase",
    color: "#000",
    fontWeight: "bold",
    fontSize: "1.12rem",
    marginBottom: "20px",
  },
  localeContainer: {
    backgroundColor: "#fff",
    position: "relative",
    padding: "30px",
    borderRadius: "5px",
  },
  localeItem: {
    display: "flex",
    flexDirection: "row",
    fontSize: "0.80rem",
    color: "#808690",
    marginBottom: "15px",
    "&:last-child": {
      marginBottom: "0px",
    },
  },
  localeItemSelected: {
    color: "#f1872c",
  },
  localeItemLabel: {
    textTransform: "uppercase",
    marginRight: "6px",
    marginLeft: "6px",
  },
});

//TODO: Add Change your langauge to Strapi backend as description
const LocaleMenuViewTablet = ({
  locales,
  selectedLocale,
  dispatchChangeLocale,
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const classes = useStyles();

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <HiOutlineGlobeAlt
        size="1.4em"
        style={{ color: "#fff" }}
        onClick={() => {
          setOpenMobileMenu(!openMobileMenu);
        }}
      />
      <Popup
        open={openMobileMenu}
        overlayStyle={{ backgroundColor: "rgba(0,0,0,.5)" }}
        closeOnDocumentClick
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.localeContainer}
        >
          <IoCloseOutline
            size="1.5em"
            style={{
              color: "#000",
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
            onClick={() => {
              setOpenMobileMenu(false);
            }}
          />
          <span className={classes.localeSelectionTitle}>
            Choose your language
          </span>
          {locales &&
            locales.map((locale, i) => (
              <div className={classes.localeItem} key={`lmi-${i}`}>
                <div
                  className={`${classes.localeItemLabel} ${
                    selectedLocale === locale.code
                      ? classes.localeItemSelected
                      : ""
                  }`}
                  onClick={() => {
                    dispatchChangeLocale(locale.code);
                    setOpenMobileMenu(false);
                  }}
                >
                  {locale.native_name}
                </div>
              </div>
            ))}
        </Grid>
      </Popup>
    </Grid>
  );
};

export default LocaleMenuViewTablet;
