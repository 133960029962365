import React from "react";

const Image = ({ imageObject, children, ...rest }) => {
  if (!imageObject.formats) {
    if (imageObject.url) {
      if (process.env.GATSBY_CDN_IMAGE_URL === "false") {
        return (
          <img
            src={`${
              imageObject.url.startsWith("/")
                ? process.env.GATSBY_STRAPI_API_LAN_URL ||
                  "http://localhost:1337"
                : ""
            }${imageObject.url}`}
            {...rest}
          />
        );
      } else {
        return (
          <img
            src={`${process.env.GATSBY_CDN_IMAGE_URL}${imageObject.hash}${imageObject.ext}`}
            {...rest}
          />
        );
      }
    }
    return null;
  }

  const formats = imageObject.formats;
  const { thumbnail, small, medium, large } = formats;

  const srcSet = [];
  if (thumbnail) {
    if (thumbnail.url.startsWith("/")) {
      // dev mode
      srcSet.push(
        `${process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337"}${
          thumbnail.url
        } ${thumbnail.width}w`
      );
    } else {
      if (process.env.GATSBY_CDN_IMAGE_URL === "false") {
        // if cdn is not ready
        srcSet.push(`${thumbnail.url} ${thumbnail.width}w`);
      } else {
        srcSet.push(
          `${process.env.GATSBY_CDN_IMAGE_URL}${thumbnail.hash}${thumbnail.ext} ${thumbnail.width}w`
        );
      }
    }
  }

  // small
  if (small) {
    if (small.url.startsWith("/")) {
      // dev mode
      srcSet.push(
        `${process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337"}${
          small.url
        } ${small.width}w`
      );
    } else {
      if (process.env.GATSBY_CDN_IMAGE_URL === "false") {
        // if cdn is not ready
        srcSet.push(`${small.url} ${small.width}w`);
      } else {
        srcSet.push(
          `${process.env.GATSBY_CDN_IMAGE_URL}${small.hash}${small.ext} ${small.width}w`
        );
      }
    }
  }

  // medium
  if (medium) {
    if (medium.url.startsWith("/")) {
      // dev mode
      srcSet.push(
        `${process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337"}${
          medium.url
        } ${medium.width}w`
      );
    } else {
      if (process.env.GATSBY_CDN_IMAGE_URL === "false") {
        // if cdn is not ready
        srcSet.push(`${medium.url} ${medium.width}w`);
      } else {
        srcSet.push(
          `${process.env.GATSBY_CDN_IMAGE_URL}${medium.hash}${medium.ext} ${medium.width}w`
        );
      }
    }
  }

  //large
  if (large) {
    if (large.url.startsWith("/")) {
      // dev mode
      srcSet.push(
        `${process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337"}${
          large.url
        } ${large.width}w`
      );
    } else {
      if (process.env.GATSBY_CDN_IMAGE_URL === "false") {
        // if cdn is not ready
        srcSet.push(`${large.url} ${large.width}w`);
      } else {
        srcSet.push(
          `${process.env.GATSBY_CDN_IMAGE_URL}${large.hash}${large.ext} ${large.width}w`
        );
      }
    }
  } else {
    //If no large, use the original size
    if (imageObject.url.startsWith("/")) {
      // dev mode
      srcSet.push(
        `${process.env.GATSBY_STRAPI_API_LAN_URL || "http://localhost:1337"}${
          imageObject.url
        } ${imageObject.width}w`
      );
    } else {
      if (process.env.GATSBY_CDN_IMAGE_URL === "false") {
        // if cdn is not ready
        srcSet.push(`${imageObject.url} ${imageObject.width}w`);
      } else {
        srcSet.push(
          `${process.env.GATSBY_CDN_IMAGE_URL}${imageObject.hash}${imageObject.ext} ${imageObject.width}w`
        );
      }
    }
  }

  return (
    <img srcSet={srcSet.join()} {...rest}>
      {children}
    </img>
  );
};

export default Image;
