import React from "react";
import { navigate } from "gatsby";

import { useSelector, useDispatch } from "react-redux";
import { changeLocale } from "../../action";

import LocaleMenuDesktopView from "./locale-menu-view-desktop";
import LocaleMenuTabletView from "./locale-menu-view-tablet";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";

const LocaleMenuController = ({ locales, location, reloadRequired }) => {
  const dispatch = useDispatch();

  const selectedLocale = useSelector(state => {
    return state.localeReducer.locale;
  });

  const handleLocale = code => {
    const paths = location.pathname.split("/");
    const foundLocaleByCode = locales.find(lo => lo.code == code);
    let url = null;

    if (!paths[1]) {
      // just domain name
      url = `/${foundLocaleByCode.display_code}`;
    } else {
      // is paths[1] valid?
      const foundLocaleByPath = locales.find(lo => lo.display_code == paths[1]);

      if (!foundLocaleByPath) {
        // do append
        url = `/${foundLocaleByCode.display_code}${location.pathname}`;
      } else {
        // /en/ro2
        const newPathsArray = location.pathname.split("/");
        newPathsArray[1] = foundLocaleByCode.display_code;
        const newPath = newPathsArray.join("/");
        console.log(`${newPath}`);

        url = `${newPath}`;
      }
    }

    if (url) {
      if (reloadRequired) {
        //when its a qian kun page, reloads the page after updating the locale
        if (typeof window != `undefined`) {
          window.location.href = `${location.origin}${url}`;
        }
      } else {
        navigate(`${url}`);
      }
    }

    dispatch(changeLocale(code));
  };

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <LocaleMenuDesktopView
          locales={locales}
          selectedLocale={selectedLocale}
          dispatchChangeLocale={code => handleLocale(code)}
        />
      }
      TabletComponent={
        <LocaleMenuTabletView
          locales={locales}
          selectedLocale={selectedLocale}
          dispatchChangeLocale={code => handleLocale(code)}
        />
      }
      PhoneComponent={
        <LocaleMenuTabletView
          locales={locales}
          selectedLocale={selectedLocale}
          dispatchChangeLocale={code => handleLocale(code)}
        />
      }
    />
  );
};

export default LocaleMenuController;
