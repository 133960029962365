import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  localeItem: {
    display: "flex",
    flexDirection: "row",
    fontSize: "0.80rem",
    color: "#fff",
    cursor: "pointer",
  },
  localeItemLabel: {
    textTransform: "uppercase",
    marginRight: "6px",
    marginLeft: "6px",
  },
});

const LocalMenuViewDesktop = ({
  locales,
  selectedLocale,
  dispatchChangeLocale,
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justify="center"
      alignItems="center"
    >
      {locales &&
        locales.map((locale, i) => (
          <Box className={classes.localeItem} key={`li-${i}`}>
            <Box
              className={`${classes.localeItemLabel} text-hover ${
                selectedLocale === locale.code ? "text-active" : ""
              }`}
              onClick={() => dispatchChangeLocale(locale.code)}
            >
              {locale.presentation_code}
            </Box>
            {i + 1 < locales.length ? "|" : ""}
          </Box>
        ))}
    </Box>
  );
};

export default LocalMenuViewDesktop;
