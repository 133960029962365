import React from "react";
// import { graphql, useStaticQuery } from "gatsby";

import { useSelector } from "react-redux";
import ResponsiveWrapper from "../responsive-wrapper";

import FooterViewDesktop from "./footer-view-desktop";
import FooterViewPhone from "./footer-view-phone";
import FooterViewTablet from "./footer-view-tablet";

const FooterController = ({ location, siteSetting, footer }) => {
  const { stakeholder_logo, social_medias } = siteSetting;
  const { copyright_notice, navigations } = footer;

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <FooterViewDesktop
          locale={locale}
          iconCompanyImage={stakeholder_logo}
          copyrightNotice={copyright_notice}
          navigations={navigations}
          bgColorCode="#333333"
        />
      }
      TabletComponent={
        <FooterViewTablet
          locale={locale}
          iconCompanyImage={stakeholder_logo}
          copyrightNotice={copyright_notice}
          navigations={navigations}
          bgColorCode="#333333"
        />
      }
      PhoneComponent={
        <FooterViewPhone
          locale={locale}
          iconCompanyImage={stakeholder_logo}
          copyrightNotice={copyright_notice}
          navigations={navigations}
          bgColorCode="#333333"
          socialMediaData={social_medias}
        />
      }
    />
  );
};

export default FooterController;
