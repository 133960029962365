/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
// import { extractImagePathUrl } from "../modules/shared-components/image/image-url-helper";
// import { graphql, useStaticQuery } from "gatsby";

// export const query = graphql`
//   query seo {
//     strapiSiteSetting {
//       copywriting_variants {
//         variants {
//           content
//           id
//           locale {
//             code
//           }
//         }
//         name
//       }
//       download_channels {
//         name
//         download_url
//         download_icon_variants {
//           locale {
//             code
//           }
//           media {
//             childImageSharp {
//               fluid(
//                 maxWidth: 150
//                 srcSetBreakpoints: [600, 1920]
//                 quality: 80
//               ) {
//                 ...GatsbyImageSharpFluid_withWebp_noBase64
//               }
//             }
//           }
//         }
//         download_icon_mobile {
//           childImageSharp {
//             fluid(maxWidth: 50, srcSetBreakpoints: [600, 1920], quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_noBase64
//             }
//           }
//         }
//       }
//       social_medias {
//         font_icon_color
//         font_icon_background_color
//         font_icon
//         display_analytics
//         id
//         name
//         url_variants {
//           content
//           locale {
//             code
//           }
//         }
//         mascot_image {
//           childImageSharp {
//             fluid(maxWidth: 200, srcSetBreakpoints: [1920], quality: 80) {
//               ...GatsbyImageSharpFluid_withWebp_noBase64
//             }
//           }
//         }
//         analytics {
//           id
//           name
//           value
//           label_variants {
//             content
//             locale {
//               code
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const SEO = ({ siteSetting }) => {
  // const { strapiSiteSetting: siteSetting } = useStaticQuery(
  //   graphql`
  //     query seo {
  //       strapiSiteSetting {
  //         site_name
  //         site_fav_icon {
  //           publicURL
  //         }
  //         site_logo {
  //           childImageSharp {
  //             fluid(maxWidth: 300, srcSetBreakpoints: [600], quality: 80) {
  //               ...GatsbyImageSharpFluid_noBase64
  //             }
  //           }
  //         }
  //         stakeholder_name
  //         stakeholder_logo {
  //           childImageSharp {
  //             fluid(maxWidth: 200, srcSetBreakpoints: [600], quality: 80) {
  //               ...GatsbyImageSharpFluid_noBase64
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  // const { strapiSiteSetting: siteSetting } = data;

  const {
    site_name,
    site_logo,
    site_fav_icon,
    site_description,
    site_meta_image,
  } = siteSetting;
  const siteMetaImageUrl = site_meta_image ? site_meta_image : site_logo;
  const metaImage = siteMetaImageUrl.url.startsWith("/")
    ? `${
        process.env.GATSBY_ENV == "development" ? "http://localhost:1337" : ""
      }${siteMetaImageUrl.url}`
    : `${process.env.GATSBY_CDN_IMAGE_URL}${siteMetaImageUrl.hash}${siteMetaImageUrl.ext}`;

  // const domain = process.env.GATSBY_RO_COM_URL || "http://localhost:8000";

  if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
    window.gtag("js", new Date());
    window.gtag("config", process.env.GATSBY_GG_ANALYTICS_RHQ);

    if (process.env.GATSBY_GG_ADS_HQ !== "false") {
      window.gtag("config", process.env.GATSBY_GG_ADS_HQ);
    }
  }

  return (
    <Helmet>
      <title>{site_name}</title>
      <link
        rel="icon"
        href={
          site_fav_icon.url.startsWith("/")
            ? `${
                process.env.GATSBY_ENV == "development"
                  ? "http://localhost:1337"
                  : ""
              }${site_fav_icon.url}`
            : `${process.env.GATSBY_CDN_IMAGE_URL}${site_fav_icon.hash}${site_fav_icon.ext}`
        }
      />
      <meta name="description" content={site_description} />
      <meta name="image" content={metaImage} />

      <meta property="og:title" content={site_name} />
      <meta property="og:image" content={metaImage} />
    </Helmet>
  );
};

export default SEO;
