import React from "react";
import Img from "gatsby-image";

import { Box } from "@material-ui/core";

import { getLocaleValue } from "../../../helpers/get-locale-value";
import SocialMediaPhone from "../social-media/social-media-view-phone";
import Navigation from "../navigation";
import Image from "../image";

const FooterView = ({
  locale,
  iconCompanyImage,
  copyrightNotice,
  navigations,
  bgColorCode,
  socialMediaData,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      bgcolor={bgColorCode}
      p={1}
      color="white"
    >
      <Box mb={2}>
        <SocialMediaPhone locale={locale} data={socialMediaData} />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        fontSize="0.9em"
      >
        {navigations?.map((n, i) => (
          <Box textAlign="center" width="100%" key={i} color="white">
            <Navigation
              style={{ color: "white" }}
              to={n.sitemap.path}
              redirect={n.sitemap.path}
              reloadRequired={n.sitemap.reload_required}
            >
              {getLocaleValue(locale, n.variants).content}
            </Navigation>
          </Box>
        ))}
      </Box>

      <Box display="flex" justifyContent="center" mt={2} alignItems="center">
        <Box mx={1} maxWidth={50}>
          <Image imageObject={iconCompanyImage} />
        </Box>
        <Box fontSize="0.9em">{copyrightNotice}</Box>
      </Box>
    </Box>
  );
};

export default FooterView;
