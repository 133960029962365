import React from "react";
import { Link } from "gatsby";
import { useSelector } from "react-redux";

const SubNavigation = ({ to, children, reloadRequired, ...rest }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const locales = useSelector(state => {
    return state.localeReducer.locales;
  }, []);

  const localeDisplayCode = locales.find(lang => lang.code == locale)
    .display_code;

  if (to.toLowerCase().startsWith("http")) {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    );
  }

  // Checking if reload is required, will use normal HREF to create reload effect
  // Else will be using gatsby default navigate
  return reloadRequired ? (
    <Link href={`/${localeDisplayCode}${to}`} {...rest}>
      {children}
    </Link>
  ) : (
    <Link to={`/${localeDisplayCode}${to}`} {...rest}>
      {children}
    </Link>
  );
};

export default SubNavigation;
