import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  box: {
    transformStyle: "preserve-3d",
  },
});

const ResponsiveWrapper = ({
  DesktopComponent,
  TabletComponent,
  PhoneComponent,
  isPreserve3D, //special handling to deal with elements with translateZ issue. cannot be applied to all in general as it will spoil the layout
}) => {
  const classes = useStyles();
  const matchesPhone = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const matchesDesktop = useMediaQuery(theme => theme.breakpoints.up("lg"));

  const getView = () => {
    if (matchesPhone) {
      return PhoneComponent;
    }
    if (matchesDesktop) {
      return DesktopComponent;
    }
    return TabletComponent;
  };

  return (
    <Box width="100%" className={isPreserve3D ? classes.box : ""}>
      {getView()}
    </Box>
  );
};

export default ResponsiveWrapper;
