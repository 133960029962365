import { getLocaleValue } from "./get-locale-value";

/**
 * Getting meta details with site settings
 *
 * @param {*} locale
 * @param {*} metaDetailsVariants
 * @param {*} siteSetting
 * @param {*} defaultMetaDetails - object with site_name, site_description, site_meta_image for fall back if meta details is undefined
 * @returns
 */
export const getMetaDetails = (
  locale,
  metaDetailsVariants,
  siteSetting,
  defaultMetaDetails
) => {
  if (metaDetailsVariants && metaDetailsVariants.length > 0) {
    const { meta_title, meta_description, meta_image } = getLocaleValue(
      locale,
      metaDetailsVariants
    );

    siteSetting.site_name = meta_title
      ? meta_title
      : defaultMetaDetails.site_name;
    siteSetting.site_description = meta_description
      ? meta_description
      : defaultMetaDetails.site_description;
    siteSetting.site_meta_image = meta_image
      ? meta_image
      : defaultMetaDetails.site_meta_image;
  }

  return siteSetting;
};
