import React, { useState } from "react";
import Img from "gatsby-image";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../helpers/get-locale-value";
import LocaleMenu from "../../locale/component/locale-menu/locale-menu-controller";

import SubNavigation from "../sub-navigation";
import Navigation from "../../shared-components/navigation";
import Image from "../image";

const HEADER_HEIGHT = "64px";
const SUB_MENU_HEIGHT = "54px";
const PADDING_HORIZONTAL = "50px";
const PADDING_VERTICAL = "0px";

const useStyles = makeStyles({
  header: {
    background: props => props.background,
    fontSize: "0.9rem",
    color: "#fff",
    minHeight: HEADER_HEIGHT,
    padding: `${PADDING_VERTICAL} ${PADDING_HORIZONTAL}`,
    position: "relative",
    zIndex: 3,
  },
  siteLogo: {
    maxWidth: "80px",
    maxHeight: HEADER_HEIGHT,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: 0,
  },
  siteStakeHolderLogo: {
    width: "50px",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: 0,
  },
  menu: {
    minHeight: HEADER_HEIGHT,
    height: HEADER_HEIGHT,
    flex: 1,
  },
  menuItem: {
    textTransform: "uppercase",
    marginLeft: "16px",
    marginRight: "16px",
    color: "#FFFFFF",

    "&:last-child": {
      marginRight: "0px",
    },
  },
  menuDropdown: {
    height: "100%",
    display: "flex",
    alignItems: "center",

    "&:hover $dropdownList": {
      display: "flex",
      alignItems: "center",
    },

    "&:hover $submenu": {
      display: "flex",
    },
  },
  menuDropdownItem: {
    textTransform: "uppercase",
    color: "#2a3180",
    marginLeft: "16px",
    marginRight: "16px",

    "&:last-child": {
      marginRight: "0px",
    },
  },
  dropdownList: {
    display: "none",
    flex: "1",
    position: "absolute",
    top: HEADER_HEIGHT,
    minHeight: SUB_MENU_HEIGHT,
    zIndex: 3,
    flexDirection: "row",
    fontSize: "0.8rem",
  },
  submenu: {
    display: "none",
    position: "absolute",
    zIndex: 2,
    top: HEADER_HEIGHT,
    minHeight: SUB_MENU_HEIGHT,
    background: "#fff",
    width: "500%",
    marginLeft: "-900px",
  },
});

//TODO: Use theme as input for color and height
const HeaderView = ({
  locale,
  locales,
  navigations,
  theme,
  location,
  reloadRequired,
}) => {
  const {
    iconSite,
    iconStakeHolder,
    siteName,
    navBgColor: inputNavBgColor,
    subNavBgColor: inputSubNavBgColor,
    mobileNavBgColor: inputMobileNavBgColor,
  } = theme;

  const classes = useStyles({ background: inputNavBgColor });

  // Get sub navigation link
  function getSubNavigationLink(
    parentUrl,
    subLink,
    subAppendParentUrl,
    subRedirect,
    subTarget,
    currentLocation
  ) {
    if (subRedirect) {
      return subRedirect;
    }
    // If no location, return sub url
    if (!currentLocation) {
      return subLink;
    }
    let modifiedSubLink;
    if (subTarget === "_anchor") {
      modifiedSubLink = subLink.startsWith("#") ? subLink : `#${subLink}`;
      if (currentLocation.pathname.includes(parentUrl)) {
        //example: in game-intro, click on game-intro->classes
        return modifiedSubLink;
      }

      // example: in homepage, click on game-intro->classes
      return subAppendParentUrl
        ? `${parentUrl}${modifiedSubLink}`
        : `${modifiedSubLink}`;
    }

    modifiedSubLink = subLink.startsWith("/") ? subLink : `/${subLink}`;
    return subAppendParentUrl
      ? `${parentUrl}${modifiedSubLink}`
      : `${modifiedSubLink}`;
  }

  // Render menu + sub menu
  function renderMenuItem(link, index) {
    const localeDisplayCode = locales.find(lang => lang.code == locale)
      .display_code;

    if (link.sub_navigations.length === 0) {
      return (
        <Navigation
          className={`text-hover ${classes.menuItem} ${
            location.pathname === link.sitemap.path ||
            location.pathname.replace(`/${localeDisplayCode}`, "") ===
              link.sitemap.path
              ? "text-active"
              : ""
          }`}
          key={`mi-${index}`}
          to={link.sitemap.path}
          redirect={link.sitemap.path}
          target={link.target}
          reloadRequired={link.sitemap.reload_required}
        >
          {getLocaleValue(locale, link.variants).content}
        </Navigation>
      );
    }
    return (
      <div className={classes.menuDropdown} key={`md-${index}`}>
        <Navigation
          className={`text-hover ${classes.menuItem} ${
            location.pathname.includes(link.sitemap.path) ? "text-active" : ""
          }`}
          to={link.sitemap.path}
          redirect={link.sitemap.path}
          target={link.target}
          reloadRequired={link.sitemap.reload_required}
        >
          {getLocaleValue(locale, link.variants).content}
        </Navigation>
        <div className={classes.dropdownList}>
          {link.sub_navigations &&
            link.sub_navigations.map((sub, i) => (
              <SubNavigation
                className={`text-hover ${classes.menuDropdownItem}`}
                key={`mdi-${i}`}
                to={sub.sitemap.path}
                reloadRequired={sub.sitemap.reload_required}
                // to={getSubNavigationLink(
                //   link.sitemap.path,
                //   sub.url,
                //   sub.append_parent_url,
                //   sub.sitemap.path,
                //   sub.target,
                //   location
                // )}
              >
                {getLocaleValue(locale, sub.variants).content}
              </SubNavigation>
            ))}
        </div>
        <div className={classes.submenu} />
      </div>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.header}
      id="header"
    >
      <Navigation to="/">
        <Image className={classes.siteLogo} imageObject={iconSite} />
      </Navigation>
      <Box
        display="flex"
        flexDirection="row"
        justify="center"
        alignItems="center"
        className={classes.menu}
      >
        {navigations && navigations.map((n, i) => renderMenuItem(n, i))}
      </Box>

      <Image
        className={classes.siteStakeHolderLogo}
        imageObject={iconStakeHolder}
      />

      <Box display="flex">
        <LocaleMenu
          locales={locales}
          location={location}
          reloadRequired={reloadRequired}
        />
      </Box>
    </Grid>
  );
};

HeaderView.defaultProps = {};

export default HeaderView;
