import React from "react";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
/**
 *
 * @param {*} reloadRequired - boolean to determine if its using normal react route or a redirect
 * @returns
 */
const Navigation = ({ to, redirect, children, reloadRequired, ...rest }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const locales = useSelector(state => {
    return state.localeReducer.locales;
  }, []);

  const localeDisplayCode = locales.find(lang => lang.code == locale)
    .display_code;

  // Checking if there is valid url for linking/redirection else route to home
  if (
    (to === "undefined" || to === null || to === "") &&
    (redirect === "undefined" || redirect === null || redirect === "")
  ) {
    <Link to="/" {...rest}>
      {children}
    </Link>;
  }

  // Checking if the path given is it a full link, if full link use redirect.
  const finalPath = redirect ? redirect : to;
  if (finalPath.toLowerCase().startsWith("http")) {
    return (
      <a href={finalPath} {...rest}>
        {children}
      </a>
    );
  }

  // Checking if reload is required, will use normal HREF to create reload effect
  // Else will be using gatsby default navigate
  return reloadRequired ? (
    <Link href={`/${localeDisplayCode}${finalPath}`} {...rest}>
      {children}
    </Link>
  ) : (
    <Link to={`/${localeDisplayCode}${finalPath}`} {...rest}>
      {children}
    </Link>
  );
};

export default Navigation;
